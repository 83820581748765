<template>
  <div @keydown.enter.prevent="handleSave">
    <v-form ref="PaymentMethodForm" @submit.prevent="handleSave">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <calendesk-information-message>
              {{ $trans("add_payment_method_dialog_subtitle") }}
            </calendesk-information-message>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-stripe-card
              ref="stripeCard"
              v-model="stripe"
              :api-key="apiKey"
              :loading="isLoadingData"
              :hide-postal-code="true"
              outlined
              :disabled="isLoadingData"
            />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-text-field
              v-model="cardName"
              :disabled="isLoadingData"
              :rules="[rules.required, rules.maxChars(255)]"
              :label="$trans('name_and_surname')"
              outlined
              :loading="isLoadingData"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import stripeActions from "@/calendesk/mixins/stripeActions";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "AddPaymentMethodDialog",
  components: { CalendeskInformationMessage },
  mixins: [sharedActions, stripeActions],
  data() {
    return {
      isLoadingData: false,
      rules: {
        required,
        maxChars,
      },
    };
  },
  methods: {
    async handleSave() {
      pushEvent("tryUpdatePaymentMethod");

      if (!this.stripe) {
        errorNotification("enter_card_data_error");
      } else if (!this.$refs.PaymentMethodForm.validate()) {
        errorNotification("form_is_invalid", {}, false);
      } else {
        this.isLoadingData = true;
        this.$emit("ctaButtonLoading", true);

        try {
          await this.processPaymentStoring();
          this.$emit("close");
          pushEvent("updatePaymentMethod");
        } catch (error) {
          errorNotification("error_occurred", error);
        }

        this.isLoadingData = false;
        this.$emit("ctaButtonLoading", false);
      }
    },
  },
};
</script>
